import React, { createContext, useState, Dispatch, SetStateAction, ReactNode } from 'react'

export type IsAutoPlayContextType = {
  isAutoPlay: boolean
  setIsAutoPlay: Dispatch<SetStateAction<boolean>>
  autoPlayTimer: number | null
  setAutoPlayTimer: Dispatch<SetStateAction<number | null>>
}

export const IsAutoPlaqyContext = createContext<IsAutoPlayContextType>({} as IsAutoPlayContextType)

export const IsAutoPlaqyProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [isAutoPlay, setIsAutoPlay] = useState(false) // AutoPlay再生か
  const [autoPlayTimer, setAutoPlayTimer] = useState<number | null>(null) // AutoPlay再生用タイマー
  return (
    <IsAutoPlaqyContext.Provider value={{ isAutoPlay, setIsAutoPlay, autoPlayTimer, setAutoPlayTimer }}>
      {children}
    </IsAutoPlaqyContext.Provider>
  )
}
