/**
 * 現在のアクティブ番号、現在のSceneIDを管理
 *
 */

import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { useListSceneData } from '../hooks/useListSceneData'
import { usePlayerData } from '../hooks/usePlayerData'
import { useSceneNumberToId } from '../hooks/useSceneNumberToId'

export type ActiveNumberContextType = {
  nextNumber: { spaceNumber: null | number; sceneNumber: null | number }
  setNextNumber: Dispatch<SetStateAction<{ spaceNumber: null | number; sceneNumber: null | number }>>
  activeNumber: { spaceNumber: null | number; sceneNumber: null | number }
  setActiveNumber: Dispatch<SetStateAction<{ spaceNumber: null | number; sceneNumber: null | number }>>
  activeSpaceId: null | number
  setActiveSpaceId: Dispatch<SetStateAction<null | number>>
  activeSceneId: null | number
  setActiveSceneId: Dispatch<SetStateAction<null | number>>
}

export const ActiveNumberContext = createContext<ActiveNumberContextType>({} as ActiveNumberContextType)
export const ActiveNumberProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [nextNumber, setNextNumber] = useState({ spaceNumber: null, sceneNumber: null }) // 次のSpace、Sceneの順番
  const [activeNumber, setActiveNumber] = useState({ spaceNumber: null, sceneNumber: null }) // 現在のSpace、Sceneの順番, keyは再レンダリング用
  const [activeSpaceId, setActiveSpaceId] = useState<null | number>(null) // 現在のSpaceID
  const [activeSceneId, setActiveSceneId] = useState<null | number>(null) // 現在のSceneID
  const { listSpaceData, setListSceneData } = usePlayerData() // list space. list scene のデータ
  const { getListSceneData } = useListSceneData() // spaceIdからlistSceneを取得
  const { sceneNumberToId } = useSceneNumberToId() // sceneNumberをIDに変換

  // 現在のSpaceの順番からSpaceID、listSceneを取得
  useEffect(() => {
    if (activeNumber.spaceNumber !== null && listSpaceData !== null) {
      //spaceNumberをIDに変換
      const spaceId = listSpaceData[activeNumber.spaceNumber]['spaceId']
      setActiveSpaceId(spaceId)
      // spaceIdからlistSceneを生成
      const scenes = getListSceneData(spaceId)
      setListSceneData(scenes)
    }
  }, [activeNumber, listSpaceData])

  // 現在のSpaceとSceneの順番からSceneIDを取得
  useEffect(() => {
    if (activeNumber.sceneNumber !== null && listSpaceData !== null) {
      // sceneNumberをIDに変換
      const spaceId = listSpaceData[activeNumber.spaceNumber]['spaceId']
      const sceneId = sceneNumberToId(spaceId, activeNumber.sceneNumber)
      setActiveSceneId(sceneId)
    }
  }, [activeNumber, listSpaceData])

  return (
    <ActiveNumberContext.Provider
      value={{
        nextNumber,
        setNextNumber,
        activeNumber,
        setActiveNumber,
        activeSpaceId,
        setActiveSpaceId,
        activeSceneId,
        setActiveSceneId,
      }}
    >
      {children}
    </ActiveNumberContext.Provider>
  )
}
