import { usePlayerData } from './usePlayerData'

export const useSceneNumberToId = () => {
  const { listSpaceData } = usePlayerData() // list space のデータ

  /**
   * sceneNumberをIDに変換
   * @param spaceId spaceId
   * @param sceneNumber sceneの順番
   * @return sceneId
   */
  const sceneNumberToId = (spaceId: number, sceneNumber: number): number => {
    let sceneId: null | number = null
    for (let i = 0; i < listSpaceData.length; i++) {
      const space = listSpaceData[i]

      if (space.spaceId === spaceId) {
        sceneId = space.scenes[sceneNumber]['sceneId']
        break
      }
    }
    if (sceneId === null) {
      alert('SceneIDが存在しません')
    } else {
      return sceneId
    }
  }
  return { sceneNumberToId }
}
