import { Image as CkakraImage, ImageProps } from '@chakra-ui/react'
import axios from 'axios'
import { memo, useEffect, useState, VFC } from 'react'
import { useMessage } from '../../../hooks/useMessage'

/**
 * リクエストヘッダーにCookieを追加
 */
type SignedImageType = {
  src: string
  onLoaded?: () => void
} & ImageProps
export const SignedImage: VFC<SignedImageType> = memo(({ src, onLoaded, ...props }) => {
  const [data, setData] = useState(null)
  const { showMessage } = useMessage()

  useEffect(() => {
    if (src) {
      axios
        .get(src, {
          withCredentials: true,
          responseType: 'blob',
        })
        .then((res) => {
          setData([URL.createObjectURL(res.data)])
        })
        .catch((error) => {
          console.log('画像が取得できません。ページを再読み込みしてください。', src, error)
          showMessage({
            text: '画像が取得できません。ページを再読み込みしてください。',
            status: 'error',
          })
        })
    }
  }, [src, onLoaded])

  return <>{data !== null && <CkakraImage src={data} {...props} onLoad={onLoaded} />}</>
})
