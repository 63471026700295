import { useCallback } from 'react'
import { config } from '../config'
import { VIEW_TYPE } from '../config/playerData'
import axiosClient from '../services/axiosInstance'
import { ProjectInfoType } from '../types/api/projectInfoType'
import { viewType } from '../types/playerDataType'
import { useMessage } from './useMessage'
import { usePlayerData } from './usePlayerData'

type fetchProjectInfoType = {
  type: viewType
}

export const useProjectInfo = () => {
  const { showMessage } = useMessage()
  const { setStatusCode, setProjectInfoData } = usePlayerData()

  const fetchProjectInfo = useCallback(async (props: fetchProjectInfoType) => {
    const { type } = props
    try {
      const res = await axiosClient.get<ProjectInfoType>(`${config.PROJECT_INFO_API}?langCode=en`)
      if (!res.data || res.data.statusCode !== 200) {
        console.log('ProjectInfoの取得に失敗しました', res)
        showMessage({
          text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
        return
      }

      if (!res.data.data.availableStream && type === VIEW_TYPE.PLAYER) {
        setStatusCode(423)
        return
      }
      setProjectInfoData(res.data.data)
      return res
    } catch (error) {
      console.log('ProjectInfoの取得に失敗しました', error)
      if (error.response.status === 400) {
        setStatusCode(400) // 閲覧権限がない場合は、ステータスを400に設定
      } else if (error.response.status === 401) {
        showMessage({
          text: '401エラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      } else if (error.response.status === 500) {
        showMessage({
          text: '500サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      } else {
        showMessage({
          text: 'サーバーエラー。問題が解決しない場合は、サイト管理者にお問い合わせください。',
          status: 'error',
        })
      }
    }
  }, [])

  return { fetchProjectInfo }
}
