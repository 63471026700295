import axios, { HeadersDefaults } from 'axios'
import { config } from '../config'
import { getTokenStorage, setTokenStorage } from './webStorage'

const axiosClient = axios.create()

type headers = {
  'Content-Type': string
  Accept: string
  Authorization: string
}

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
} as headers & HeadersDefaults

// Authorizationヘッダーを追加
axiosClient.interceptors.request.use(
  (config) => {
    const accessToken = getTokenStorage('accessToken')
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true
      const accessToken = getTokenStorage('accessToken')
      const refreshToken = getTokenStorage('refreshToken')
      try {
        const fetchRefreshToken = async () => {
          const resRefresh = await axios
            .post(
              config.PLAYER_REFRESH_TOKEN_API,
              {
                refreshToken: refreshToken,
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            .then((res: any) => {
              return res
            })
            .catch((err: any) => {
              return err.response
            })

          if (resRefresh.status === 200) {
            const { data } = resRefresh.data
            setTokenStorage(data)
          } else if (resRefresh.status === 401) {
            // 改めて実装のため、一旦refreshTokenをコメントアウト
            // const projectId = JSON.parse(sessionStorage.getItem('projectId')).projectId
            // console.log('auth ：projectId', projectId)
            // try {
            //   const resAuth = await axios
            //     .post(
            //       config.PLAYER_AUTH_API,
            //       { projectId: `${projectId}` },
            //       {
            //         headers: { 'X-API-KEY': `${process.env.REACT_APP_X_API_KEY}` },
            //         withCredentials: true,
            //       }
            //     )
            //     .then((res: any) => {
            //       console.log('auth：レスポンス', res)
            //       return res
            //     })
            //     .catch((err: any) => {
            //       console.log('auth：エラー', err)
            //       return err.response
            //     })
            //   console.log('auth ：res', resAuth)
            //   if (resAuth.data) {
            //     if (resAuth.status === 200) {
            //       console.log('auth 2：成功')
            //       const auth = resAuth.data.data
            //       localStorage.setItem('token', JSON.stringify(auth))
            //       fetchRefreshToken()
            //     } else {
            //       console.log('auth認証に失敗しました')
            //       return Promise.reject()
            //     }
            //   } else {
            //     console.log('auth認証に失敗しました')
            //     return Promise.reject()
            //   }
            // } catch (_error) {
            //   console.log('auth：失敗', _error)
            //   return Promise.reject(_error)
            // }
          }
          return axiosClient(originalConfig)
        }
        const returnRefreshToken = fetchRefreshToken()
        return returnRefreshToken
      } catch (_error) {
        return Promise.reject(_error)
      }
    }

    return Promise.reject(err)
  }
)

export default axiosClient
