import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { ListLayerDataType } from '../types/api/listLayerDataType'
import { ListSceneDataType } from '../types/api/listSceneDataType'
import { ListSpaceDataType } from '../types/api/listSpaceDataType'
import { ProjectInfoDataType } from '../types/api/projectInfoDataType'
import { SceneInfoDataType } from '../types/api/sceneInfoDataType'
import { viewType } from '../types/playerDataType'

export type PlayerDataContextType = {
  projectPublishId: string
  setProjectPublishId: Dispatch<SetStateAction<string | null>>
  viewType: viewType | null
  setViewType: Dispatch<SetStateAction<viewType | null>>
  statusCode: number
  setStatusCode: Dispatch<SetStateAction<number | null>>
  projectInfoData: ProjectInfoDataType
  setProjectInfoData: Dispatch<SetStateAction<ProjectInfoDataType | null>>
  listLayerData: ListLayerDataType
  setListLayerData: Dispatch<SetStateAction<ListLayerDataType | null>>
  listSpaceData: ListSpaceDataType
  setListSpaceData: Dispatch<SetStateAction<ListSpaceDataType | null>>
  listSceneData: ListSceneDataType
  setListSceneData: Dispatch<SetStateAction<ListSceneDataType | null>>
  sceneInfoData: SceneInfoDataType
  setSceneInfoData: Dispatch<SetStateAction<SceneInfoDataType | null>>
}

export const PlayerDataContext = createContext<PlayerDataContextType>({} as PlayerDataContextType)

export const PlayerDataProvider = (props: { children: ReactNode }) => {
  const { children } = props
  const [projectPublishId, setProjectPublishId] = useState<string | null>(null)
  const [viewType, setViewType] = useState<viewType | null>(null) // type [player, preview]
  const [statusCode, setStatusCode] = useState<number | null>(null) // status code
  const [projectInfoData, setProjectInfoData] = useState<ProjectInfoDataType | null>(null) // project info
  const [listLayerData, setListLayerData] = useState<ListLayerDataType | null>(null) // list layer
  const [listSpaceData, setListSpaceData] = useState<ListSpaceDataType | null>(null) // list space
  const [listSceneData, setListSceneData] = useState<ListSceneDataType | null>(null) // list scene
  const [sceneInfoData, setSceneInfoData] = useState<SceneInfoDataType | null>(null) // scene info

  return (
    <PlayerDataContext.Provider
      value={{
        projectPublishId,
        setProjectPublishId,
        viewType,
        setViewType,
        statusCode,
        setStatusCode,
        projectInfoData,
        setProjectInfoData,
        listLayerData,
        setListLayerData,
        listSpaceData,
        setListSpaceData,
        listSceneData,
        setListSceneData,
        sceneInfoData,
        setSceneInfoData,
      }}
    >
      {children}
    </PlayerDataContext.Provider>
  )
}
